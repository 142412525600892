import React from "react"

const underConstruction = () => (
  <>
    <h1 className="yellowTapeDivider donotcross">Είμαι υπό κατασκευή, σύντομα κοντά σας!</h1>
<div className="container">
  {/* <h1 className="text-center">Είμαι υπό κατασκευή και επιστρέφω σύντομα!</h1> */}
  <svg id="walle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.4 129.4">
  <defs>
    <clipPath id="clipPath" transform="translate(-81.8 -924.4)">
      <circle cx="140.5" cy="978.2" r="4.3" fill="none"/>
    </clipPath>
    <clipPath id="clipPath-2" transform="translate(-81.8 -924.4)">
      <path d="M148.5,976.5s1.3,5.2-3.2,6.7l3.4,3.6,9.3-2.1-5-8.3Z" fill="none"/>
    </clipPath>
    <clipPath id="clipPath-3" transform="translate(-81.8 -924.4)">
      <circle cx="155.8" cy="983.6" r="4.3" fill="none"/>
    </clipPath>
    <clipPath id="clipPath-4" transform="translate(-81.8 -924.4)">
      <path d="M130.4,1007.6s1.3-.4,1.9.7a1.5,1.5,0,0,1-.3,1.8c-.2.3-4.5,2.9-4.5,2.9l-20.6,13.7-3.5-6.4Z" fill="none"/>
    </clipPath>
    <clipPath id="clipPath-5" transform="translate(-81.8 -924.4)">
      <path d="M150.6,1000s1.4.3,1.3,1.5a1.5,1.5,0,0,1-1.1,1.5c-.3.2-5.3.5-5.3.5l-24.6,2.3v-7.3Z" fill="none"/>
    </clipPath>
  </defs>
  <g id="Layer_3" data-name="Layer 3">
    <ellipse id="shadow" cx="71.5" cy="100.4" rx="26.5" ry="3.6" fill="#394848" opacity="0.35"/>
  </g>
  <g id="Layer_2" data-name="Layer 2">
    <g className="head">
    <g id="neck">
      <g id="neckb">
        <path d="M157.8,995.6a2.6,2.6,0,1,1-4.1-2.2,6.1,6.1,0,0,0-1.7-.3c-1-.2-.9-1.3-.8-1.8a1.6,1.6,0,0,1-.5-1.2,1.7,1.7,0,1,1,3.4,0,1.6,1.6,0,0,1-.5,1.2,5.8,5.8,0,0,0,1.7,1.8A2.6,2.6,0,0,1,157.8,995.6Z" transform="translate(-81.8 -924.4)" fill="#a2b2b3"/>
        <circle cx="70.6" cy="65.6" r="0.9" fill="#636767"/>
        <circle cx="73.3" cy="71.2" r="1.6" fill="none" stroke="#636767" strokeMiterlimit="10" strokeWidth="0.5"/>
      </g>
      <g id="neckf">
        <path id="wire" d="M147.3,989.3s-2-1.9-.7-3.2l3.4-3.7.3.9s-2.6,2.5-3.2,3.3.9,2.5.9,2.5Z" transform="translate(-81.8 -924.4)" fill="#394848"/>
        <path fill="#a2b2b3" d="M64.6 67.1L65.3 67.6 69.3 67.5 69.6 67 64.6 67.1z"/>
        <path d="M149.8,983.4l.2,2.6-1.9,2.3-1.7.2s-2.5,1.9.3,3.1h4.8s-1.9-2,.5-2.8l-1.5-.2,2.4-2.3L152,983Z" transform="translate(-81.8 -924.4)" fill="#ddeced"/>
        <path d="M147,992l4.1-.2,1.1,1.3h2.1a3,3,0,0,0-1.7,2.4h-7.6a2.6,2.6,0,0,1,2.3-2.6Z" transform="translate(-81.8 -924.4)" fill="#ddeced"/>
      </g>
    </g>
    <g id="eyecontain">
    <g id="eyes">
      <g id="lefteye">
        <path id="lefteyeb2" d="M143.8,972.7l-6.1,1.1a6.2,6.2,0,0,0-4.1,8s2.3,4.4,6.6,3.7l5.4-.6a5.7,5.7,0,0,0,4.4-6l-.6-4.1S148.7,971.8,143.8,972.7Z" transform="translate(-81.8 -924.4)" fill="#a2b2b3"/>
        <path id="lefteyeb1" d="M145.1,972.1l-8.1,1s-5.8,1.6-4.1,7.3a4.9,4.9,0,0,0,5.6,3.4l5.4-.6s4.6-.8,4.4-5.4l-.6-4.6S147.9,971.6,145.1,972.1Z" transform="translate(-81.8 -924.4)" fill="#ddeced"/>
        <g id="lefteyeball">
          <circle cx="58.7" cy="53.8" r="4.3" fill="#0c1112"/>
          <g clipPath="url(#clipPath)">
            <circle id="ball" cx="59.7" cy="54.8" r="4.3" fill="#394848"/>
          </g>
          <circle id="glimmer" cx="61.5" cy="51.2" r="1.4" fill="#fff"/>
        </g>
      </g>
      <g id="eyespace">
        <g clipPath="url(#clipPath-2)">
          <circle cx="66.4" cy="56.1" r="3.6" fill="#f49123"/>
        </g>
      </g>
      <g id="righteye">
        <path id="righteyeb2" d="M158.8,978.7l4.1,4.7a6.2,6.2,0,0,1-1.8,8.8s-4.5,2.1-7.4-1.2l-3.9-3.9a5.7,5.7,0,0,1,.2-7.4l3-2.8S155.5,974.9,158.8,978.7Z" transform="translate(-81.8 -924.4)" fill="#a2b2b3"/>
        <path id="righteyeb1" d="M156.4,976l5.4,6.1s3.3,5-1.8,8.2a4.9,4.9,0,0,1-6.4-1.2l-3.7-4s-2.9-3.6.3-7l3.5-3.1S154.7,973.8,156.4,976Z" transform="translate(-81.8 -924.4)" fill="#ddeced"/>
        <g id="righteyeball">
          <circle cx="74" cy="59.2" r="4.3" fill="#0c1112"/>
          <g clipPath="url(#clipPath-3)">
            <circle id="ball-2" data-name="ball" cx="75" cy="60.2" r="4.3" fill="#394848"/>
          </g>
          <circle id="glimmer-2" data-name="glimmer" cx="74.7" cy="56.1" r="1.4" fill="#fff"/>
        </g>
      </g>
    </g>
    </g>
    </g>
    <g className="body">
    <g id="backwheel">
      <path d="M149.9,1007.2l-4.8-.2s-.6-.2-2,1.2l-12.5,12.7s-1.4,2.3.3,3.5a3.9,3.9,0,0,0,3.1.8h7.1Z" transform="translate(-81.8 -924.4)" fill="#394848"/>
      <path d="M152.4,1009.2s-2-4.6-6.5-.1l-11,11.2s-3.8,4.1,1.4,4.8h16.2s7.8.2,5.5-7.4Z" transform="translate(-81.8 -924.4)" fill="#636767"/>
      <path d="M151.7,1010.8s-1.7-3.8-5.3-.1l-9,9.1s-3.1,3.3,1.2,3.9h13.3s6.4.1,4.6-6Z" transform="translate(-81.8 -924.4)" fill="#0c1112"/>
    </g>
    <path d="M148.7,1024h3.2a5,5,0,0,0,2.6,1.1h-4.2S148.9,1024.7,148.7,1024Z" transform="translate(-81.8 -924.4)" fill="#0c1112"/>
    <g id="rightarm">
      <path d="M140.9,999.3l-10.9,6.9a3.2,3.2,0,0,0-.4,4.5c1.9,2.6,5,.1,5,.1l9.2-5.1s3.8-2.3,1.9-5.1A3.8,3.8,0,0,0,140.9,999.3Z" transform="translate(-81.8 -924.4)" fill="#c6690c"/>
      <g clipPath="url(#clipPath-4)">
       
         <path id="newrightarm" d="M130.6,1008.4l10.8-6.2a.6.6,0,0,1,.8,0c.3.3.8.5.1,1.1s-10.8,6.5-10.8,6.5-.8.5-1,.4-.6-.9-.6-.9S129.6,1009.1,130.6,1008.4Z" transform="translate(-81.8 -924.4)" fill="#a2b2b3"/>
      </g>
      <circle cx="48.5" cy="85" r="1.3" fill="#636767"/>
      <g id="backhand">
        <path d="M131,1011.5c.9.6,1.6-.2,2.4-.4a3.8,3.8,0,0,1,1.5-.3c.1.8-.4,1.4-.5,2.1l-1.4.4c-1.5.7-2.8.7-3.9-.7a.9.9,0,0,0-.5-.7,2,2,0,0,1-.5-1.8c-.1-.7.5-.8.9-1s1.8.7,1.7,1.9A.5.5,0,0,0,131,1011.5Zm-1.1-.9c-.1-.3-.2-.6-.6-.6s-.6.1-.6.5a.7.7,0,0,0,.7.7C129.8,1011.2,129.9,1010.9,130,1010.7Z" transform="translate(-81.8 -924.4)" fill="#636767"/>
        <path d="M133.4,1016.1a3.9,3.9,0,0,1-4.3,3.6c-.9-.1-1.3-.9-1.8-1.6a5.5,5.5,0,0,1,.5-1.8c.2-.8.4-.6.8-.1a1.8,1.8,0,0,0,2.4.7Z" transform="translate(-81.8 -924.4)" fill="#424e4f"/>
        <path d="M129.1,1012.7c1.1,1.4,2.4,1.4,3.9.7l1.4-.4-.6,2.2-3,1.1a1.3,1.3,0,0,1-1.5-.4C128.2,1014.8,128.2,1014.1,129.1,1012.7Z" transform="translate(-81.8 -924.4)" fill="#424e4f"/>
        <path d="M129.1,1012.7c-1,1.4-.9,2.1.1,3.1a1.3,1.3,0,0,0,1.5.4l3-1.1-.3.9-2.4.7a1.8,1.8,0,0,1-2.4-.7c-.3-.5-.6-.7-.8.1a5.5,5.5,0,0,0-.5,1.8l-.5-.5a33.5,33.5,0,0,1,1.7-5.6A.9.9,0,0,1,129.1,1012.7Z" transform="translate(-81.8 -924.4)" fill="#394848"/>
        <path d="M128.7,1012a33.5,33.5,0,0,0-1.7,5.6,1.6,1.6,0,0,1-.5-2c.5-1.5.9-3,1.3-4.5v-.2a.6.6,0,0,1,.3-.6A2,2,0,0,0,128.7,1012Z" transform="translate(-81.8 -924.4)" fill="#424e4f"/>
        <path d="M130,1010.7c-.1.3-.2.6-.5.6a.7.7,0,0,1-.7-.7c0-.3.3-.5.6-.5S129.9,1010.3,130,1010.7Z" transform="translate(-81.8 -924.4)" fill="#424e4f"/>
      </g>
    </g>
    <g id="front">
      <path fill="#f49123" d="M55.1 73.1H76.6V92.91999999999999H55.1z"/>
      <path d="M136.3,1014.3h2.8s.9-.1.9,1,.1,1.8.1,1.8a.9.9,0,0,1-.8.8h-2s-1.2-.4-1.2-1.4A4,4,0,0,1,136.3,1014.3Z" transform="translate(-81.8 -924.4)" fill="#ddeced"/>
      <rect x="54.8" y="72.9" width="21.2" height="7.67" rx="1" ry="1" fill="#ddeced"/>
      <path d="M157.1,997.9h-20c-.3,0-.5-.1-.5-.3s.2-.2.5-.2h20c.3,0,.5.1.5.3S157.4,997.9,157.1,997.9Z" transform="translate(-81.8 -924.4)" fill="#636767" opacity="0.41"/>
      <rect x="57.8" y="81.5" width="1.3" height="6.42" rx="0.7" ry="0.7" fill="#f2791e"/>
      <rect x="71.5" y="81.4" width="1.3" height="6.42" rx="0.7" ry="0.7" fill="#f2791e"/>
      <rect x="64.8" y="81.5" width="1.3" height="6.42" rx="0.7" ry="0.7" fill="#f2791e"/>
      <rect x="53.6" y="71.2" width="23" height="1.92" rx="1" ry="1" fill="#ddeced"/>
      <path fill="#a2b2b3" d="M59.5 73.1H70.3V79.1H59.5z"/>
      <path fill="#bedb45" d="M66.2 74.1H68.4V77.92999999999999H66.2z"/>
      <path fill="#636767" d="M61.3 74H64.2V78H61.3z"/>
      <circle cx="62.4" cy="75.6" r="0.5" fill="#f76f6f"/>
    </g>
    <g id="back">
      <rect x="74.5" y="71.2" width="13" height="1.75" rx="0.9" ry="0.9" fill="#a2b2b3"/>
      <path fill="#f2791e" d="M86.3 80.4L86.3 83.1 81.2 83 76.5 87.3 76.4 80.3 86.3 80.4z"/>
      <path fill="#a2b2b3" d="M76.4 72.1H86.30000000000001V80.6H76.4z"/>
      <path d="M167.9,997.7h-9.5a.3.3,0,0,1,0-.5h9.5a.3.3,0,0,1,0,.5Z" transform="translate(-81.8 -924.4)" fill="#636767"/>
      <rect x="75.9" y="73" width="0.6" height="19.31" rx="0.3" ry="0.3" fill="#636767" opacity="0.34"/>
    </g>
    <g id="forwardwheel">
      <path fill="none" stroke="#0c1112" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.25" d="M68.1 100.7L90.6 100.7"/>
      <path d="M165.8,1007.5H161s-.6-.2-1.9,1.2l-12,12.2s-1.3,2.2.3,3.3a3.8,3.8,0,0,0,3,.8h6.8Z" transform="translate(-81.8 -924.4)" fill="#0c1112" opacity="0.41"/>
      <path d="M167.9,1007.2l-4.8-.2s-.6-.2-2,1.2l-12.5,12.7s-1.4,2.3.3,3.5a3.9,3.9,0,0,0,3.1.8h7.1Z" transform="translate(-81.8 -924.4)" fill="#394848"/>
      <path d="M170.4,1009.2s-2-4.6-6.5-.1l-11,11.2s-3.8,4.1,1.4,4.8h16.2s7.8.2,5.5-7.4Z" transform="translate(-81.8 -924.4)" fill="#a2b2b3"/>
      <path d="M169.7,1010.8s-1.7-3.8-5.3-.1l-9,9.1s-3.1,3.3,1.2,3.9h13.3s6.4.1,4.6-6Z" transform="translate(-81.8 -924.4)" fill="#636767"/>
      <path d="M173.6,1017.2l-3-1.7s-11.2.1-6,8.1h5.9Z" transform="translate(-81.8 -924.4)" fill="#394848" opacity="0.78"/>
      <path d="M165.1,1010l1.6,4.6s-1.3-.6-1.3,1.2-3.4,3.2-3.4,3.2-1.2-.3-1.4,1.2-2.6,3.4-2.6,3.4H155s-3.5-.1-.4-3h0Z" transform="translate(-81.8 -924.4)" fill="#394848" opacity="0.78"/>
      <g id="wheel">
        <circle cx="85.4" cy="87.6" r="2.9" fill="#a2b2b3"/>
        <circle cx="85.4" cy="87.6" r="2" fill="#ddeced"/>
        <ellipse cx="85.1" cy="87.6" rx="1.1" ry="0.8" fill="#394848"/>
        <circle cx="85.5" cy="87.6" r="0.8" fill="#a2b2b3"/>
      </g>
      <g id="wheel-2" data-name="wheel">
        <circle cx="81" cy="92.2" r="2.9" fill="#a2b2b3"/>
        <circle cx="81" cy="92.2" r="2" fill="#ddeced"/>
        <ellipse cx="80.7" cy="92.2" rx="1.1" ry="0.8" fill="#394848"/>
        <circle cx="81.1" cy="92.2" r="0.8" fill="#a2b2b3"/>
      </g>
      <g id="wheel-3" data-name="wheel">
        <circle cx="76.7" cy="96.5" r="2.9" fill="#a2b2b3"/>
        <circle cx="76.7" cy="96.5" r="2" fill="#ddeced"/>
        <ellipse cx="76.4" cy="96.5" rx="1.1" ry="0.8" fill="#394848"/>
        <circle cx="76.8" cy="96.5" r="0.8" fill="#a2b2b3"/>
      </g>
      <g id="wheel-4" data-name="wheel">
        <circle cx="88.9" cy="95.2" r="4.1" fill="#a2b2b3"/>
        <circle cx="88.9" cy="95.2" r="2.8" fill="#ddeced"/>
        <ellipse cx="88.5" cy="95.2" rx="1.6" ry="1.1" fill="#394848"/>
        <circle cx="89" cy="95.2" r="1.1" fill="#a2b2b3"/>
      </g>
    </g>
    <g id="leftarm">
      <g id="bicep">
        <path d="M165.2,998.3H151.2a3.5,3.5,0,0,0-2.7,4.2c.4,2.3,4.4,2.4,4.4,2.4l11.9-.3s4.2.8,3.9-2.8A3.5,3.5,0,0,0,165.2,998.3Z" transform="translate(-81.8 -924.4)" fill="#f2791e"/>
        <path d="M154.1,998.4h12.7s2.2.2,2.2,3.1c0,0,.3,2.8-1.8,3s-13.1.3-13.1.3Z" transform="translate(-81.8 -924.4)" fill="#ddeced"/>
        <path fill="#636767" d="M72.3 78L75.9 73.8 78.5 73.9 73.5 80.2 72.3 80.2 72.3 78z"/>
        <path fill="#636767" d="M76 80.2L81.4 73.9 84 73.9 79 80.1 76 80.2z"/>
        <path d="M163.9,1004.5l4.3-5.4a2.6,2.6,0,0,1,.6,1.9l-2.8,3.5Z" transform="translate(-81.8 -924.4)" fill="#636767"/>
      </g>
      <g clipPath="url(#clipPath-5)">
        <path id="newleftarm" d="M150.1,1000.7s-1.8-.3-2,.9,1.8,1,1.8,1l17-.2s1.1-.7.3-1.5S150.1,1000.7,150.1,1000.7Z" transform="translate(-81.8 -924.4)" fill="#a2b2b3"/>
      </g>
      <g id="hand">
        <ellipse cx="66.8" cy="77.3" rx="0.9" ry="0.9" fill="#636767"/>
        <path d="M146.1,998.7l4.5,4.9a.8.8,0,0,1-.3,1.1l-3.5,2.1s-.5,0-.4.8,1,1.5.1,2-5.7-2.6-5.7-2.6l-2-4.5a1.7,1.7,0,0,1,1.3-2Z" transform="translate(-81.8 -924.4)" fill="#a2b2b3"/>
        <path d="M147.2,1001.1l-4.3,1.1a1.6,1.6,0,0,0-1.1,2.1c.3,1.2,1,3.2,1,3.2" transform="translate(-81.8 -924.4)" fill="none" stroke="#636767" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5" opacity="0.46"/>
      </g>
    </g>
    </g>
  </g>
</svg>
</div>
  </>
)

export default underConstruction
